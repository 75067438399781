.containerPolitica {
  margin-left: 20px;
  margin-right: 20px;
  text-align: justify;
}

.bannerNovoApp {
  background-color: #f0f8ff;
  border: 2px solid #0056b3;
  padding: 20px;
  margin-top: 20px;
  text-align: center;
  border-radius: 10px;
}

.bannerNovoApp h3 {
  color: #0056b3;
}

.bannerNovoApp p {
  color: #333;
}

.botaoDownload {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 15px;
  background-color: #0056b3;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
}

.botaoDownload:hover {
  background-color: #003d7a;
}
