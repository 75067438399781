.navbar {
  display: flex;
  justify-content: flex-start;
  font-size: 16px;
  border: 0px solid black;
  background-color: #2499f9;
}

.navbarList {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  border: 0px solid black;
}

.navbarItem {
  margin: 5px 0px 5px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid black; */
}

.navbarLink {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  transition: color 0.3s;
}

.navbarLink:hover {
  color: #aaff00;
}

/* Responsividade */
@media screen and (max-width: 600px) {
  .navbar {
    flex-direction: column;
    align-items: center;
  }
  .navbarItem {
    margin: 8px 0;
  }
}
