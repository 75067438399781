.insideMain {
  display: flex;
  margin: 0 auto;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  padding: 30px;
}

.subtitle {
  font-size: 22px;
  background-color: #f9ca24;
  font-family: Helvetica;
  color: black;
  padding: 10px 20px;
  border-radius: 8px; /* Borda arredondada */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Sombra leve */
}

.subtitle2 {
  margin-top: 10px;
  font-size: 14px;
  background-color: #2499f9; /* Cor de fundo */
  color: #fff; /* Cor do texto */
  padding: 5px 10px; /* Espaçamento interno */
  border-radius: 8px; /* Borda arredondada */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Sombra leve */
}
